import React from 'react';
import '../css/header.css'

function Header() {
  return (
    <div className="header">
      Foodies!
    </div>
  );
}

export default Header;
